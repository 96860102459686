import { inject, Pipe, PipeTransform } from '@angular/core';
import { AccessService } from '../services/authorization/access.service';
import { GlobalStore } from '../store/global.store';

@Pipe({
  name: 'hasAccess',
  standalone: true,
})
export class HasAccessPipe implements PipeTransform {
  store = inject(GlobalStore);
  accessService = inject(AccessService);


  public transform(id: string | boolean | undefined): boolean {
    return this.accessService.hasAccess(id, this.store.user());
  }
}
